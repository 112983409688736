@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Italic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-SemiBoldItalic.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "Montserrat";
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
}
@font-face {
    font-family: "iconfont";
    src: url("../fonts/iconfont/iconfont.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}